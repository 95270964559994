import { bannerButtonsInlineStyleCommon } from "./";

function bannerButtonsInlineStyleAcceptEssentials(_ref, isHover, framed, order, isTwoButtonRowLayout) {
  var isTcf = _ref.isTcf,
      acceptEssentials = _ref.decision.acceptEssentials,
      borderRadius = _ref.layout.borderRadius,
      _ref$bodyDesign = _ref.bodyDesign,
      acceptEssentialsFontSize = _ref$bodyDesign.acceptEssentialsFontSize,
      acceptEssentialsBg = _ref$bodyDesign.acceptEssentialsBg,
      acceptEssentialsTextAlign = _ref$bodyDesign.acceptEssentialsTextAlign,
      acceptEssentialsBorderColor = _ref$bodyDesign.acceptEssentialsBorderColor,
      acceptEssentialsPadding = _ref$bodyDesign.acceptEssentialsPadding,
      acceptEssentialsBorderWidth = _ref$bodyDesign.acceptEssentialsBorderWidth,
      acceptEssentialsFontColor = _ref$bodyDesign.acceptEssentialsFontColor,
      acceptEssentialsFontWeight = _ref$bodyDesign.acceptEssentialsFontWeight,
      acceptEssentialsHoverBg = _ref$bodyDesign.acceptEssentialsHoverBg,
      acceptEssentialsHoverFontColor = _ref$bodyDesign.acceptEssentialsHoverFontColor,
      acceptEssentialsHoverBorderColor = _ref$bodyDesign.acceptEssentialsHoverBorderColor,
      acceptAllFontWeight = _ref$bodyDesign.acceptAllFontWeight,
      acceptAllFontSize = _ref$bodyDesign.acceptAllFontSize,
      acceptAllPadding = _ref$bodyDesign.acceptAllPadding,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return bannerButtonsInlineStyleCommon({
    name: "accept-essentials",
    order: order,
    type: acceptEssentials,
    borderRadius: borderRadius,
    bg: acceptEssentialsBg,
    hoverBg: acceptEssentialsHoverBg,
    fontSize: isTwoButtonRowLayout || isTcf ? acceptAllFontSize : acceptEssentialsFontSize,
    textAlign: acceptEssentialsTextAlign,
    fontColor: acceptEssentialsFontColor,
    fontWeight: isTcf ? acceptAllFontWeight : acceptEssentialsFontWeight,
    hoverFontColor: acceptEssentialsHoverFontColor,
    borderWidth: acceptEssentialsBorderWidth,
    borderColor: acceptEssentialsBorderColor,
    hoverBorderColor: acceptEssentialsHoverBorderColor,
    padding: isTwoButtonRowLayout ? acceptAllPadding : acceptEssentialsPadding,
    antiAdBlocker: antiAdBlocker,
    pageRequestUuid4: pageRequestUuid4
  }, mobile, isHover, framed, isTwoButtonRowLayout);
}

export { bannerButtonsInlineStyleAcceptEssentials };