import { useEffect } from "react";
import { useBanner } from "../contexts";
import { parents } from "@devowl-wp/headless-content-unblocker";
import { matchingSelector } from "@devowl-wp/resolve-none-computed-style";
var HREF_MATCHER = '[href^="#consent-"]';
/**
 * Listen to anchor links and execute the banner action:
 *
 * ```
 * #consent-change
 * #consent-history
 * #consent-revoke
 * ```
 */

function useBannerActionLinks() {
  var _useBanner = useBanner(),
      openBanner = _useBanner.openBanner,
      openHistory = _useBanner.openHistory,
      revokeConsent = _useBanner.revokeConsent;

  useEffect(function () {
    var handleType = function handleType(type, successMessage, e) {
      if (!openBanner) {
        // `useBannerActions` is not yet initialized.
        return;
      }

      switch (type) {
        case "change":
          openBanner(e);
          break;

        case "history":
          openHistory(e);
          break;

        case "revoke":
          revokeConsent(successMessage, e);
          break;

        default:
          break;
      }
    };

    var eventListenerDocumentClick = function eventListenerDocumentClick(e) {
      if (!openBanner) {
        // `useBannerActions` is not yet initialized.
        return;
      } // Check if one of the parents has a valid hash link


      var element = e.target;
      parents(element, HREF_MATCHER).concat(matchingSelector(element, HREF_MATCHER) ? [element] : []).forEach(function (parent) {
        handleType(parent.getAttribute("href").slice(9), parent.getAttribute("data-success-message"), e);
      }); // @deprecated Old behavior with simple links like `#change`

      if (matchingSelector(element, ".rcb-sc-link")) {
        handleType(element.getAttribute("href").slice(1), element.getAttribute("data-success-message"), e);
      }
    };

    var eventListenerHashChange = function eventListenerHashChange() {
      var hash = window.location.hash;

      if (hash.startsWith("#consent-")) {
        handleType(hash.substring(9), undefined, undefined);
      }
    };

    window.addEventListener("hashchange", eventListenerHashChange);
    document.addEventListener("click", eventListenerDocumentClick, true);
    return function () {
      window.removeEventListener("hashchange", eventListenerHashChange);
      document.removeEventListener("click", eventListenerDocumentClick, true);
    };
  }, [openBanner, openHistory, revokeConsent]);
}

export { useBannerActionLinks };