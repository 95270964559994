import { useBannerLegalLinks } from ".";
import { expandGroupsServiceVariable } from "../others";
import { getOtherOptionsFromWindow } from "../utils";

function joinWithAndSeparator(arr, andSeparator) {
  if (arr.length > 1) {
    arr.splice(arr.length - 1, 0, "{{andSeparator}}");
  }

  return arr.join(", ").replace(/,\s+{{andSeparator}},\s+/g, andSeparator);
}
/**
 * @see https://gist.github.com/unruthless/413930
 * @see https://stackoverflow.com/a/23630325/5506547
 */


var supWithLineHeightFix = '<sup style="vertical-align:top;line-height:100%;">';

function useBannerTexts(banner) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      disableDataProcessingInUnsafeCountries = _ref.disableDataProcessingInUnsafeCountries,
      disableAgeNotice = _ref.disableAgeNotice,
      disableListServicesNotice = _ref.disableListServicesNotice;

  var blocker = banner.blocker,
      groups = banner.groups,
      isEPrivacyUSA = banner.ePrivacyUSA,
      isAgeNotice = banner.ageNotice,
      isListServicesNotice = banner.listServicesNotice,
      _banner$texts = banner.texts,
      description = _banner$texts.description,
      ePrivacyUSA = _banner$texts.ePrivacyUSA,
      ageNoticeBanner = _banner$texts.ageNoticeBanner,
      listServicesNotice = _banner$texts.listServicesNotice,
      consentForwardingExternalHostsText = _banner$texts.consentForwardingExternalHosts,
      legal = banner.legal,
      tcf = banner.tcf,
      consentForwardingExternalHosts = banner.consentForwardingExternalHosts,
      individualPrivacyOpen = banner.individualPrivacyOpen,
      individualDescription = banner.individualTexts.description,
      essentialGroup = banner.essentialGroup;

  var _useBannerLegalLinks = useBannerLegalLinks(legal),
      linkPrivacyPolicy = _useBannerLegalLinks.linkPrivacyPolicy;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      isCurrentlyInTranslationEditorPreview = _getOtherOptionsFromW.isCurrentlyInTranslationEditorPreview,
      _getOtherOptionsFromW2 = _getOtherOptionsFromW.bannerI18n,
      andSeparator = _getOtherOptionsFromW2.andSeparator,
      tcfBannerI18n = _getOtherOptionsFromW2.tcf; // Description text (combination of configured text and Consent Forwarding)


  var consentForwardingExternalHostsFinalText = "";

  if (consentForwardingExternalHosts && !isCurrentlyInTranslationEditorPreview) {
    consentForwardingExternalHostsFinalText = consentForwardingExternalHostsText.replace(/{{websites}}/g, consentForwardingExternalHosts.join(", "));
  }

  var useDescription = [blocker ? blocker.description : individualPrivacyOpen ? individualDescription : description, consentForwardingExternalHostsFinalText].filter(Boolean).join(" ");

  if (!isCurrentlyInTranslationEditorPreview) {
    // Place policy link into the text https://regex101.com/r/ayeFVy/1
    useDescription = useDescription.replace(/{{privacyPolicy}}(.*){{\/privacyPolicy}}/g, linkPrivacyPolicy ? "<a href=\"".concat(linkPrivacyPolicy.url, "\" style=\"text-decoration:underline;color:inherit;\" onmouseenter=\"this.style.textDecoration='none'\" onmouseleave=\"this.style.textDecoration='underline'\" target=\"_blank\">$1</a>") : "$1");
  } // Teaching: Data processing in unsafe countries (formerly USA ePrivacy)


  var useDataProcessingInUnsafeCountries = !disableDataProcessingInUnsafeCountries ? expandGroupsServiceVariable(groups, tcf ? {
    gvl: tcf.gvl,
    vendorConfigurations: tcf.original.vendorConfigurations
  } : undefined, isEPrivacyUSA ? ePrivacyUSA : "", function (cookie, tcf) {
    return (cookie === null || cookie === void 0 ? void 0 : cookie.ePrivacyUSA) || (tcf === null || tcf === void 0 ? void 0 : tcf.vendorConfiguration.ePrivacyUSA) === 1;
  }, 3, true) : ""; // Teaching: Age notice

  var useAgeNotice = isAgeNotice && !disableAgeNotice ? ageNoticeBanner : ""; // Teaching: List services notice

  var useListServicesNotice = "";

  if (isListServicesNotice && !disableListServicesNotice) {
    var hasBothPlaceholders = listServicesNotice.indexOf("{{services}}") > -1 && listServicesNotice.indexOf("{{serviceGroups}}") > -1;
    var nonEssentialGroups = groups.filter(function (_ref2) {
      var slug = _ref2.slug;
      return slug !== essentialGroup;
    });
    var flattenSeparator = "~;~";
    var listServicesNames = joinWithAndSeparator(nonEssentialGroups.map(function (_ref3, groupIdx) {
      var items = _ref3.items;
      return items.map(function (_ref4) {
        var name = _ref4.name,
            ePrivacyUSA = _ref4.ePrivacyUSA;
        return "".concat(name).concat(supWithLineHeightFix).concat([hasBothPlaceholders ? groupIdx + 1 : "", useDataProcessingInUnsafeCountries && ePrivacyUSA ? "U" : ""].filter(Boolean).join(","), "</sup>");
      }).join(flattenSeparator);
    }).join(flattenSeparator).split(flattenSeparator), andSeparator);

    if (listServicesNames) {
      var listServiceGroupsNames = joinWithAndSeparator(nonEssentialGroups.map(function (_ref5, groupIdx) {
        var name = _ref5.name;
        return "".concat(name).concat(supWithLineHeightFix).concat(hasBothPlaceholders ? groupIdx + 1 : "", "</sup>");
      }), andSeparator);
      useListServicesNotice = "<span>".concat(listServicesNotice, "</span>").concat(tcf && !individualPrivacyOpen ? "<span>".concat(tcfBannerI18n.listOfServicesAppendix, "</span>") : "");

      if (!isCurrentlyInTranslationEditorPreview) {
        // Place service list into the text
        useListServicesNotice = useListServicesNotice.replace(/{{services}}/g, listServicesNames) // Place service group list into the text
        .replace(/{{serviceGroups}}/g, listServiceGroupsNames);
      }

      if (useDataProcessingInUnsafeCountries) {
        useDataProcessingInUnsafeCountries += "".concat(supWithLineHeightFix, "U</sup>");
      }
    }
  }

  return {
    description: useDescription,
    teachings: [useDataProcessingInUnsafeCountries, useAgeNotice, useListServicesNotice].filter(Boolean)
  };
}

export { useBannerTexts };