import { BannerDottedGroup, bannerInlineStyleBodyDottedGroups } from ".";
import { useBanner } from "../../contexts";

var BannerDottedGroups = function BannerDottedGroups() {
  var banner = useBanner();
  var groups = banner.groups,
      showGroups = banner.decision.showGroups;
  return showGroups ? h("div", bannerInlineStyleBodyDottedGroups(banner), groups.filter(function (_ref) {
    var items = _ref.items;
    return !!items.length;
  }).map(function (group) {
    return h(BannerDottedGroup, {
      key: group.id,
      group: group
    });
  })) : null;
};

export { BannerDottedGroups };