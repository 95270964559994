import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { getOtherOptionsFromWindow } from "../../utils";
var ATTRIBUTE_EXPRIVACY_NOTE_EXPAND = "eprivacy-usa-expand";
var addedEventListener = false;
/**
 * Replace in a given (HTML) string `{{services}}` with a given filter.
 *
 * @deprecated Will be removed in v3
 */

function expandGroupsServiceVariable( // Pass available services as values
groups, tcf, text, // Pass a callback to filter the passed available services by criteria
filter) {
  var truncateAfter = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 3;
  var noCookiesReturnEmptyString = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      _getOtherOptionsFromW2 = _getOtherOptionsFromW.bannerI18n,
      ePrivacyNoteMore = _getOtherOptionsFromW2.ePrivacyNoteMore,
      none = _getOtherOptionsFromW2.none;

  var restLabels = [];
  var labels = []; // Usual cookies

  var _iterator = _createForOfIteratorHelper(groups),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var items = _step.value.items;

      var _iterator2 = _createForOfIteratorHelper(items),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _cookie = _step2.value;

          if (filter(_cookie)) {
            labels.push(_cookie.name);
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  if (process.env.IS_TCF === "1" && tcf) {
    // TCF vendor configurations
    var gvl = tcf.gvl,
        vendorConfigurations = tcf.vendorConfigurations;

    for (var vendorConfigurationId in vendorConfigurations) {
      var vendorConfiguration = vendorConfigurations[vendorConfigurationId];
      var vendor = gvl.vendors[vendorConfiguration.vendorId];

      if (filter(undefined, {
        vendor: vendor,
        vendorConfiguration: vendorConfiguration
      })) {
        labels.push(vendor.name);
      }
    }
  } // Truncate when more than 1 difference


  if (labels.length - truncateAfter > 1) {
    restLabels = labels.slice(truncateAfter, labels.length);
    labels = labels.slice(0, truncateAfter); // Unfortunately we can not use React here because the text is customized inside a text

    labels.push("<span ".concat(ATTRIBUTE_EXPRIVACY_NOTE_EXPAND, "=\"").concat(restLabels.join(", "), "\" style=\"text-decoration:none;border-bottom:0.5px dashed;padding-bottom:1px;cursor:pointer;\">").concat(ePrivacyNoteMore.replace("%d", restLabels.length.toString()), "</span>")); // Add event-listener globally once as we are not using React here

    !addedEventListener && document.addEventListener("click", function (e) {
      var target = e.target;

      if (target !== null && target !== void 0 && target.hasAttribute(ATTRIBUTE_EXPRIVACY_NOTE_EXPAND)) {
        target.innerHTML = target.getAttribute(ATTRIBUTE_EXPRIVACY_NOTE_EXPAND);
        target.removeAttribute("style");
      }
    });
    addedEventListener = true;
  }

  if (noCookiesReturnEmptyString && labels.length === 0) {
    return "";
  }

  return text.replace(/{{services}}/g, labels.length ? labels.join(", ") : "<span style=\"color:red;\">".concat(none, "</span>"));
}

export { expandGroupsServiceVariable };