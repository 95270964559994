import { bannerButtonsInlineStyleCommon } from "./";

function bannerButtonsInlineStyleAcceptIndividual(_ref, isHover, framed, order, isTwoButtonRowLayout) {
  var acceptIndividual = _ref.decision.acceptIndividual,
      borderRadius = _ref.layout.borderRadius,
      _ref$bodyDesign = _ref.bodyDesign,
      acceptIndividualFontSize = _ref$bodyDesign.acceptIndividualFontSize,
      acceptIndividualBg = _ref$bodyDesign.acceptIndividualBg,
      acceptIndividualTextAlign = _ref$bodyDesign.acceptIndividualTextAlign,
      acceptIndividualBorderColor = _ref$bodyDesign.acceptIndividualBorderColor,
      acceptIndividualPadding = _ref$bodyDesign.acceptIndividualPadding,
      acceptIndividualBorderWidth = _ref$bodyDesign.acceptIndividualBorderWidth,
      acceptIndividualFontColor = _ref$bodyDesign.acceptIndividualFontColor,
      acceptIndividualFontWeight = _ref$bodyDesign.acceptIndividualFontWeight,
      acceptIndividualHoverBg = _ref$bodyDesign.acceptIndividualHoverBg,
      acceptIndividualHoverFontColor = _ref$bodyDesign.acceptIndividualHoverFontColor,
      acceptIndividualHoverBorderColor = _ref$bodyDesign.acceptIndividualHoverBorderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return bannerButtonsInlineStyleCommon({
    name: "accept-individual",
    order: order,
    type: acceptIndividual,
    borderRadius: borderRadius,
    bg: acceptIndividualBg,
    hoverBg: acceptIndividualHoverBg,
    fontSize: acceptIndividualFontSize,
    textAlign: acceptIndividualTextAlign,
    fontColor: acceptIndividualFontColor,
    fontWeight: acceptIndividualFontWeight,
    hoverFontColor: acceptIndividualHoverFontColor,
    borderWidth: acceptIndividualBorderWidth,
    borderColor: acceptIndividualBorderColor,
    hoverBorderColor: acceptIndividualHoverBorderColor,
    padding: acceptIndividualPadding,
    antiAdBlocker: antiAdBlocker,
    pageRequestUuid4: pageRequestUuid4
  }, mobile, isHover, framed, isTwoButtonRowLayout);
}

export { bannerButtonsInlineStyleAcceptIndividual };